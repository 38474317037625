@import url("https://fonts.googleapis.com/css2?family=Helvetica+Neue:wght@300;400;500&display=swap");

:root {
  --font-primary: "Helvetica Neue", -apple-system, BlinkMacSystemFont, Arial,
    sans-serif;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  letter-spacing: 0.02em;
}

/* navigation menu */
.nav-link {
  font-weight: 300;
  letter-spacing: 0.05em;
}

/* head */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
  font-weight: 400;
  letter-spacing: 0.03em;
}

/* product name */
.product-info {
  font-family: var(--font-primary);
  font-weight: 300;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

/* price style */
.price {
  font-family: var(--font-primary);
  font-weight: 400;
}

/* button style */
.btn {
  font-family: var(--font-primary);
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
