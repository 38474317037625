body {
  background-color: white;
}

.card {
  border: none !important;
  margin-bottom: 10px;
}

.error-message {
  text-align: left;
  width: 100%;
}
.form-control:valid {
  background-color: white !important;
}

.drop-down {
  margin-top: 20px;
}

.add-button {
  margin-top: 20px;
  width: 100%;
}

.side-menu {
  height: 100vh;
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: scroll;
  transition: all 0.3s ease;
  width: 200px;
  padding-top: 60px;
}

.side-menu-list button {
  margin: 5px;
  border: none;
  background: none;
  width: 120px;
}

.closebtn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  margin-left: 50px;
  border: none;
  background: none;
}

.side-menu-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-menu-list button:hover {
  background-color: beige;
  border-radius: 3px;
}

/* ----------------- */
.search-input-container {
  max-width: 400px;
  width: 100%;
}

.search-input-container .input-group {
  border-bottom: 1px solid #dee2e6;
}

.search-input-container input {
  box-shadow: none !important;
}

.search-input-container input:focus {
  border-color: transparent;
}
.cursor-pointer {
  cursor: pointer;
}
.badge {
  font-size: 12px;
  min-width: 18px;
  padding: 3px;
}

/* 네비게이션바 로고 중앙 정렬 */
.navbar .container-fluid {
  justify-content: space-between;
  align-items: center;
}

/* 네비게이션바 높이 조절 */
.navbar {
  min-height: 70px;
}

/* 사이드바 메뉴 호버 효과 */
.nav-item button:hover {
  background-color: #f8f9fa;
}

/* 미니멀한 버전 */
.pagination {
  gap: 2px;
}

.page-item .page-link {
  color: #000;
  background-color: transparent;
  border: none;
  padding: 6px 10px;
  font-size: 0.75rem;
  line-height: 1;
  min-width: 28px;
  text-align: center;
}

.page-item.active .page-link {
  background-color: #000;
  color: #fff;
  border-radius: 2px;
}

/* previous/next 버튼 스타일 */
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/* 호버 효과를 더 섬세하게 */
.page-item .page-link:hover:not(.active) {
  background-color: rgba(0, 0, 0, 0.05);
  color: #000;
}

/* disabled 상태를 더 섬세하게 */
.page-item.disabled .page-link {
  opacity: 0.5;
  color: #000;
}
