.status-card{
    background-color: white;
    padding:10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 20px;
}
.status-card:hover{
    background-color: rgba(238, 30, 30, 0.148);
}
.vertical-middle{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.status-card-container{
    margin-bottom: 20px;
    max-width: 750px;
   
}




.order-button-area{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.order-button-area button{
    margin-left: 20px;
}

.text-align-center{
    text-align: center;
    margin-bottom: 5px;
}
.text-12{
    font-size: 12px;

}

.no-order-box{
    background-color: white;
    text-align: center;
    padding:20px
}

@media screen and (max-width: 63rem) {
   .order-info{
    margin-left: 20px;
    }
  }

  